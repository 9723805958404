import React from 'react';
import classnames from 'classnames';
import * as styles from './style.module.scss';

import Icon from 'components/UI/Icon';

const LargeLink = ({ data, className }) => (
  <a
    href={data.to?.uri}
    className={classnames(styles.largelink, className)}
    rel="noreferrer"
    target="_blank"
  >
    <div className={styles.largelink_l}>
      <p className={classnames('cr-lead', styles.largelink_title)}>
        {data.title}
      </p>
      <p className={classnames('cr-reg')}>{data.subtitle}</p>
    </div>
    <div className={styles.largelink_r}>
      <Icon
        name={'corner-arrow-small-bold'}
        className={classnames('cr-text-3', styles.largelink_arrow)}
      />
    </div>
  </a>
);

export default LargeLink;
