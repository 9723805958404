import React from 'react';
import classnames from 'classnames';
import FooterNavigation from './components/FooterNavigation';
import FooterContacts from './components/FooterContacts';
import FooterInfo from './components/FooterInfo';
import * as styles from './footer.module.scss';
import './styles.scss';

import useReusableBlocks from 'hooks/graphql/useReusableBlocks';
import useLocale from 'hooks/useLocale';

const Footer = () => {
  const locale = useLocale();
  const { footer } = useReusableBlocks();

  const DATA_SUBSCRIBE = {
    title: footer.subscribeTitle,
    subtitle: footer.subscribeSubtitle,
  };

  const DATA_SOCIALS = {
    title: footer.socialsTitle,
    subtitle: footer.socialsSubtitle,
    button: footer.socialButton,
  };

  const DATA_CONTACT = {
    title: footer.contactsTitle,
    subtitle: footer.contactsSubtitle,
  };

  const DATA_PRIVACY = {
    titlePartners: footer.partnersTitle,
    owner1: {
      title: footer.ownersTitle1,
    },
    owner2: {
      title: footer.ownersTitle2,
      href: footer.ownersHref2,
    },
    copyRight: footer.copyrightText,
    privacyLink: footer.privacyLink,
    termsLinks: footer.termsLinks,
    developed: footer.developed,
  };

  return (
    <footer className={classnames(styles.footer)}>
      <div className={classnames(styles.inner, 'cr-wrap')}>
        <FooterNavigation locale={locale} />
        <FooterContacts
          subscribe={DATA_SUBSCRIBE}
          soicials={DATA_SOCIALS}
          contacts={DATA_CONTACT}
        />
        <FooterInfo info={DATA_PRIVACY} />
      </div>
    </footer>
  );
};

export default Footer;
