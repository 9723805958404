import { useStaticQuery, graphql } from 'gatsby';

const contacts = graphql`
  query getContactsOnSite {
    allWpContact {
      edges {
        node {
          contactsOnSite {
            type
            value
          }
        }
      }
    }
  }
`;

const setContactLink = ({ type, value }) => {
  switch (type) {
    case 'email':
      return `mailto:${value}`;
    case 'phone':
      return `tel:${value}`;
    default:
      return value;
  }
};

const useContactOnSite = () => {
  const {
    allWpContact: { edges },
  } = useStaticQuery(contacts);

  return edges
    .map(({ node: { contactsOnSite } }) => contactsOnSite)
    .map(({ type, value }) => ({
      type,
      value,
      href: setContactLink({ type, value }),
    }));
};

export default useContactOnSite;
