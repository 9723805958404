import { useCallback, useMemo } from 'react';
import { ACTIONS_CREATORS, useContextDispatch } from '@/context';
import { useLocation } from '@reach/router';

const useHashModals = () => {
  const dispatch = useContextDispatch();
  const location = useLocation();

  const HASH_MODALS = useMemo(
    () => ({
      'get-cirrusoft-modal': ACTIONS_CREATORS.setGetCirrusoftModal,
      'subscribe-modal': ACTIONS_CREATORS.setSubscribeModal,
    }),
    []
  );

  const openModalByHash = useCallback(
    (hash = '') => {
      const actionCreator = HASH_MODALS[hash.slice(1)];

      if (!actionCreator) return;

      dispatch(actionCreator(true));
    },
    // eslint-disable-next-line
    [HASH_MODALS, dispatch, location]
  );

  return openModalByHash;
};

export default useHashModals;
