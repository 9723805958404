import GetCirrusoft from './index';
import useModalWithContext from 'hooks/useModalWithContext';

import { ACTIONS_CREATORS, MODALS } from '@/context';

const GetCirrusoftModalWithContext = () =>
  useModalWithContext(
    MODALS.GET_CIRRUSOFT,
    ACTIONS_CREATORS.setGetCirrusoftModal,
    GetCirrusoft
  );

export default GetCirrusoftModalWithContext;
