import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';

import useDeviceState from 'hooks/useDeviceState';
import { DESKTOP_KEY } from 'constants/devices';

import Dropdown from '../Dropdown';

import { getMainMenu } from '../../helpers/getPositionMenu';
import { useMenuData } from '../../hooks/useMenu';

import * as styles from './navigation.module.scss';

const Navigation = ({ locale }) => {
  const menu = useMenuData();
  const deviceState = useDeviceState();

  const {
    menuItems: { nodes },
  } = locale.isDefault
    ? menu.find((item) => getMainMenu(item))
    : menu.find((item) => getMainMenu(item, locale.code));

  return (
    <nav className={classnames(styles.nav)}>
      {nodes
        .sort((a, b) => a.order - b.order)
        .map((item) =>
          item?.childItems?.nodes?.length > 0 ? (
            deviceState === DESKTOP_KEY && (
              <div
                key={item.url}
                className={classnames(styles.nav_item, 'cr-subheader-2')}
              >
                <Dropdown
                  DropdownData={{
                    button: item.label,
                    list: item.childItems.nodes,
                  }}
                />
              </div>
            )
          ) : (
            <p
              key={item.url}
              className={classnames(styles.nav_item, 'cr-subheader-2')}
            >
              <Link to={item.path} className={classnames('cr-link')}>
                {item.label}
              </Link>
            </p>
          )
        )}
    </nav>
  );
};

export default Navigation;
