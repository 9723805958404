import React, { useRef, useState, useEffect, useCallback } from 'react';
import useHover from '@react-hook/hover';
import classnames from 'classnames';
import FocusMenu from 'components/FocusMenu';
import Icon from 'components/UI/Icon';

import useClickOutside from 'hooks/useClickOutside';

import * as styles from './dropdown.module.scss';

const Dropdown = ({ DropdownData }) => {
  const targetHover = useRef(null);
  const isHovering = useHover(targetHover, { enterDelay: 100, leaveDelay: 0 });

  const [isShowPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setShowPopup(isHovering);
  }, [isHovering]);

  useClickOutside(targetHover, () => {
    setShowPopup(false);
  });

  const handleClick = useCallback(() => {
    setShowPopup((state) => !state);
  }, [setShowPopup]);

  return (
    <div className={classnames(styles.dropdown)} ref={targetHover}>
      <p
        className={classnames(
          isShowPopup && 'cr-dropdown-open',
          'cr-subheader-2 cr-dropdown-arrow'
        )}
        onClick={handleClick}
      >
        <span>{DropdownData.button}</span>
        <Icon name="chevron-down" />
      </p>

      {isShowPopup ? (
        <div className={classnames(styles.menu)}>
          <FocusMenu
            data={DropdownData.list.sort((a, b) => a.order - b.order)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
