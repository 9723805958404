import React, { useMemo } from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

const RadioBtn = ({
  className = '',
  children,
  isActive = false,
  onChange = () => {},
  name,
}) => {
  const statusCssClasses = useMemo(
    () => ({
      [styles.active]: isActive,
    }),
    [isActive]
  );

  return (
    <label className={classnames(styles.radio, statusCssClasses, className)}>
      <input
        type="radio"
        name={name}
        className={styles.radio_input}
        onChange={onChange}
      />
      {children}
    </label>
  );
};

export default RadioBtn;
