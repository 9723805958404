import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import * as styles from './langList.module.scss';

const LangList = ({ listLanguages, currentLanguage }) => (
  <ul className={classnames(styles.list)}>
    {listLanguages.map((lang) => (
      <li key={lang.slug}>
        <Link
          to={`/${lang.path}`}
          className={classnames(styles.link, {
            [styles.activeLink]: currentLanguage.code === lang.code,
          })}
        >
          {lang.code}
        </Link>
      </li>
    ))}
  </ul>
);

export default LangList;
