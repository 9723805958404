import { useStaticQuery, graphql } from 'gatsby';

const formQuery = graphql`
  query FormRegistrationQuery {
    allGfForm {
      nodes {
        apiURL
        formId
        formFields {
          id
          label
          labelPlacement
          description
          descriptionPlacement
          type
          choices
          content
          errorMessage
          inputMaskValue
          isRequired
          visibility
          cssClass
          placeholder
          size
          defaultValue
          maxLength
          conditionalLogic
          emailConfirmEnabled
        }
        description
        button {
          text
          type
        }
        slug
        title
      }
    }
  }
`;

export const useGravityFormData = (id) => {
  const {
    allGfForm: { nodes },
  } = useStaticQuery(formQuery);

  return nodes.find(({ formId }) => formId === id);
};
