import React from 'react';

import Modal from 'components/UI/Modal';
import GetCirrusoft from 'components/Forms/GetCirrusoft';

const GetCirrusoftModal = ({ isOpen, onClose }) => (
  <Modal
    label={'Get Cirrusoft'}
    isOpen={isOpen}
    onClose={onClose}
    maxWidth={'1200px'}
    className={'cr-modal--dark-btn'}
    overlayClassName={'cr-modal-overlay--as-page-on-mobile'}
  >
    <GetCirrusoft />
  </Modal>
);

export default GetCirrusoftModal;
