import { useMemo } from 'react';

import { useLanguagesList } from 'hooks/graphql/useLanguagesList.js';

import useLocale from 'hooks/useLocale';

const useLanguage = () => {
  const { defaultLanguage, languages } = useLanguagesList();

  const locale = useLocale();

  const listLanguages = useMemo(
    () => [
      {
        ...defaultLanguage,
        path: '',
      },
      ...languages
        .filter((language) => language.code !== defaultLanguage.code)
        .map((language) => ({
          ...language,
          path: language.slug,
        })),
    ],
    [defaultLanguage, languages]
  );

  const currentLanguage = useMemo(
    () => listLanguages.find((language) => language.code === locale.code),
    [listLanguages, locale.code]
  );

  return {
    listLanguages,
    currentLanguage,
  };
};

export default useLanguage;
