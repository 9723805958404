import React, { useCallback, useRef, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { CSSTransition } from 'react-transition-group';

// import Icon from "components/UI/Icon";
import LangSwitch from 'components/UI/LangSwitch';
import Button from 'components/UI/Button';
import BurgerBtn from 'components/UI/BurgerBtn';
import Navigation from './Navigation';
import MobileMenu from './MobileMenu';
import HeaderLogo from 'assets/svg/HeaderLogo';

import useDeviceState from 'hooks/useDeviceState';
import { DESKTOP_KEY } from 'constants/devices';
import useGetCirrusoftModal from 'hooks/useGetCirrusoftModal';
import useLocale from 'hooks/useLocale';

import * as styles from './header.module.scss';
import 'styles/common/_transitions.scss';
import useReusableBlocks from 'hooks/graphql/useReusableBlocks';

const Header = (props) => {
  const locale = useLocale();
  const wrapScroll = useRef(null);
  const deviceState = useDeviceState();
  const {
    forms_ui: {
      buttons: { getCirrusoft },
    },
  } = useReusableBlocks();

  const { openGetCirrusModal } = useGetCirrusoftModal();

  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = useCallback(() => {
    setOpenMenu(!openMenu);
  }, [openMenu]);

  const addFixedScroll = () => {
    const refs = wrapScroll.current;

    disableBodyScroll(refs, { reserveScrollBarGap: true });
  };

  // TODO: найти решение по лучше, может вынести в контекст?
  const onOpenGetCirrusoftHandler = useCallback(() => {
    setOpenMenu(false);
  }, [setOpenMenu]);

  return (
    <header className={classnames(props.className, styles.header)}>
      <div className={classnames(styles.inner, 'cr-wrap')}>
        <Link to={locale.path} className={classnames(styles.logo)}>
          <span hidden>Home Page</span>
          <HeaderLogo />
        </Link>

        <div className={classnames(styles.controls, 'cr-desktop-only')}>
          <Navigation locale={locale} />

          <Button
            className={classnames(styles.btn, 'cr-btn--default')}
            onClick={openGetCirrusModal}
          >
            {getCirrusoft}
          </Button>

          <LangSwitch />
        </div>

        <BurgerBtn
          className={classnames('cr-mobile-only')}
          handleClick={toggleMenu}
          burgerState={openMenu}
        />
      </div>

      {deviceState !== DESKTOP_KEY && (
        <CSSTransition
          in={openMenu}
          classNames="cr-css-transition--slide-down"
          timeout={{
            enter: 400,
            exit: 200,
          }}
          onExited={clearAllBodyScrollLocks}
          onEntering={addFixedScroll}
          unmountOnExit
        >
          <div className={classnames(styles.wrapMobileMenu)} ref={wrapScroll}>
            <MobileMenu
              locale={locale}
              onOpenGetCirrusoft={onOpenGetCirrusoftHandler}
            />
          </div>
        </CSSTransition>
      )}
    </header>
  );
};

export default Header;
