import { useStaticQuery, graphql } from 'gatsby';

const useSocialData = () => {
  const {
    wp: {
      seo: { social },
    },
  } = useStaticQuery(
    graphql`
      query GetSocialsFromSetts {
        wp {
          seo {
            social {
              facebook {
                url
              }
              instagram {
                url
              }
              linkedIn {
                url
              }
              twitter {
                username
              }
            }
          }
        }
      }
    `
  );

  return Object.entries(social)
    .filter(([, value]) => Object.values(value)[0])
    .map(([key, value]) => ({
      name: key,
      icon: `social-${key}`,
      url: value.url || `https://twitter/${value.username}`,
    }));
};

export default useSocialData;
