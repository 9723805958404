import React from 'react';
import classnames from 'classnames';
import * as styles from './burgerBtn.module.scss';

const BurgerBtn = ({ className, handleClick, burgerState }) => (
  <button
    type="button"
    className={classnames(styles.burger, className, {
      [styles.close]: burgerState,
    })}
    onClick={handleClick}
  ></button>
);

export default BurgerBtn;
