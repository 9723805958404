import React from 'react';
import classnames from 'classnames';
import * as styles from './owners.module.scss';

import BicaLogo from 'images/bica-logo.png';
import LantLogo from 'images/lantzerath-group-logo.png';

const Owners = ({ owners }) => {
  const ownersLogo = [BicaLogo, LantLogo];

  return (
    <ul className={classnames(styles.list)}>
      {ownersLogo.map((item, index) => {
        return (
          <li key={item} className={classnames(styles.item)}>
            {owners[index].href ? (
              <a
                href={owners[index].href}
                target="_blank"
                rel="noreferrer"
                className={classnames(styles.title, 'cr-opacity-02 cr-sub-2')}
              >
                {owners[index].title}
              </a>
            ) : (
              <p className={classnames(styles.title, 'cr-opacity-02 cr-sub-2')}>
                {owners[index].title}
              </p>
            )}

            <p>
              <img src={item} alt="" />
            </p>
          </li>
        );
      })}
    </ul>
  );
};

export default Owners;
