import { useCallback } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

const useStaticImage = () => {
  const {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    query getCloudLogosImages {
      allFile {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  `);

  return useCallback(
    (path) => nodes.find(({ relativePath }) => relativePath === path),
    [nodes]
  );
};

export default useStaticImage;
