import * as Yup from 'yup';
import MESSAGES from './errorsMessages';

const getRules = (locale = 'EN') => ({
  required: Yup.string().required(MESSAGES[locale].required),
  email: Yup.string()
    .email(MESSAGES[locale].email)
    .required(MESSAGES[locale].required),
  accept: Yup.boolean().oneOf([true], MESSAGES[locale].accept),
});

export default getRules;
