import React, { useEffect, useState, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import parse from 'html-react-parser';
import useIsMounted from 'react-is-mounted-hook';

import Button from 'components/UI/Button';
import useReusableSections from 'hooks/graphql/useReusableBlocks';

import { getAnswer, setConfirmed, setDecline } from '@/helpers/storage';

import * as styles from './cookiesPopup.module.scss';

let timer = null;

const CookiesPopup = () => {
  const {
    cookiesPopup: { content, acceptButton, declineButton },
  } = useReusableSections();
  const [isHidden, setHidden] = useState(true);
  const isMounted = useIsMounted();

  const handleGetConfirmedStatus = useCallback(() => {
    timer = setTimeout(() => {
      isMounted && setHidden(getAnswer());
    }, 5000);
  }, [isMounted]);

  useEffect(() => {
    handleGetConfirmedStatus();

    return () => clearTimeout(timer);
  }, [handleGetConfirmedStatus]);

  const handleConfirmed = useCallback(() => {
    setConfirmed();
    setHidden(true);
  }, []);

  const handleDecline = useCallback(() => {
    setHidden(true);
    setDecline();

    document.cookie.split('; ').forEach((el) => {
      const key = el.split('=')[0];

      if (!['_ga', '_gid'].includes(key)) return false;

      document.cookie = key + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    });

    delete window.ga;
  }, []);

  return (
    <CSSTransition
      in={!isHidden}
      classNames="cr-css-transition--slide-up"
      timeout={{
        enter: 400,
        exit: 400,
      }}
      unmountOnExit
    >
      <div className={classnames(styles.wrapper)}>
        <div className={'cr-wrap'}>
          <div className={styles.flex}>
            <div className={classnames(styles.content, 'cr-sub')}>
              {parse(content)}
            </div>
            <div className={styles.buttons}>
              <p>
                <Button
                  className={classnames('cr-btn--icon-red-bg')}
                  onClick={handleConfirmed}
                >
                  {acceptButton}
                </Button>
              </p>

              <p>
                <Button onClick={handleDecline}>{declineButton}</Button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default CookiesPopup;
