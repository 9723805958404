import React from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const LinkElement = ({ to, children, ...props }) => {
  return to.includes('#') ? (
    <AnchorLink to={to} {...props}>
      {children}
    </AnchorLink>
  ) : (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

export default LinkElement;
