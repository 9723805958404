import React from 'react';
import classnames from 'classnames';
// import SubscribeContainer from './SubscribeContainer';
import SocialsContainer from './SocialsContainer';
import ContactsContainer from './ContactsContainer';
import * as styles from './footerContacts.module.scss';

const FooterContacts = ({ subscribe, soicials, contacts }) => {
  return (
    <div className={classnames(styles.footerContacts)}>
      <div className={classnames(styles.inner)}>
        {/* TODO: пока что временно, а там хуй его знает */}
        {/* <div
          className={classnames(
            styles.subscribe,
            "cr-footer-first-flex cr-footer-block-right-gap"
          )}
        >
          <SubscribeContainer subscribe={subscribe} />
        </div> */}

        <div
          className={classnames(
            styles.socials,
            'cr-footer-second-flex cr-footer-block-right-gap'
          )}
        >
          <SocialsContainer socials={soicials} />
        </div>

        <div className={classnames(styles.contacts)}>
          <ContactsContainer support={contacts} />
        </div>
      </div>
    </div>
  );
};

export default FooterContacts;
