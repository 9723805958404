import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import * as styles from './privacy.module.scss';

const Privacy = ({ privacy, terms }) => (
  <ul className={classnames(styles.list)}>
    {privacy.uri && (
      <li>
        <Link
          to={privacy.uri}
          className={classnames('cr-sub cr-link cr-opacity-02')}
        >
          {privacy.title}
        </Link>
      </li>
    )}
    {terms.uri && (
      <li>
        <Link to={terms.uri} className={'cr-sub cr-link cr-opacity-02'}>
          {terms.title}
        </Link>
      </li>
    )}
  </ul>
);

export default Privacy;
