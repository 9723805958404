import React from 'react';

import * as styles from './Spinner.module.scss';
const Picture = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='margin:auto;display:block;' width='200px' height='200px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3E%3Cpath d='M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50' fill='%23e15b64' stroke='none'%3E%3CanimateTransform attributeName='transform' type='rotate' dur='1s' repeatCount='indefinite' keyTimes='0;1' values='0 50 51;360 50 51'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E`;

const Spinner = ({ size = '', absolute = false }) => {
  return (
    <div
      className={styles.wrap}
      style={{ fontSize: size, position: absolute && 'absolute' }}
    >
      <img src={Picture} alt="spinner" />
    </div>
  );
};

export default Spinner;
