const getAttributes = (type = 'main', code) => {
  switch (type) {
    case 'main':
      return code
        ? [`PRIMARY___${code}`, `EXPANDED___${code}`, `MOBILE___${code}`]
        : [`PRIMARY`, `EXPANDED`, `MOBILE`];
    case 'footer':
      return code ? [`FOOTER___${code}`] : [`FOOTER`];
    default:
      return code
        ? [`PRIMARY___${code}`, `EXPANDED___${code}`, `MOBILE___${code}`]
        : [`PRIMARY`, `EXPANDED`, `MOBILE`];
  }
};

export const getMainMenu = (item, code = null) => {
  const positionLabel = getAttributes('main', code);

  return item.locations.find((locationsItem) =>
    positionLabel.includes(locationsItem)
  );
};

export const getFooterMenu = (item, code = null) => {
  const positionLabel = getAttributes('footer', code);

  return item.locations.find((locationsItem) =>
    positionLabel.includes(locationsItem)
  );
};
