import { useCallback, useEffect, useState } from 'react';

import {
  DESKTOP_KEY,
  MOBILE_KEY,
  MOBILE_WIDTH,
  TABLET_KEY,
  TABLET_WIDTH,
} from 'constants/devices';

const DEFAULT_SETTINGS = {
  mobile: MOBILE_WIDTH,
  tablet: TABLET_WIDTH,
};

const useDeviceState = (obj = {}) => {
  const { mobile, tablet } = {
    ...DEFAULT_SETTINGS,
    ...obj,
  };

  const [deviceState, setDeviceState] = useState(DESKTOP_KEY);

  const handleResize = useCallback(() => {
    if (window.innerWidth <= mobile) {
      setDeviceState(MOBILE_KEY);

      return;
    }

    if (window.innerWidth <= tablet) {
      setDeviceState(TABLET_KEY);

      return;
    }

    setDeviceState(DESKTOP_KEY);
  }, [mobile, tablet]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return deviceState;
};

export default useDeviceState;
