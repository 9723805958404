import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

import Icon from 'components/UI/Icon';
// TODO: использовать если вернемся к списку соц сетей в виде круглых иконок
// import Socials from "components/Elements/Socials/Links";
import * as styles from './socialsContainer.module.scss';
import useSocialData from 'hooks/graphql/useSocials';

const SocialsContainer = ({ socials }) => {
  const socialsData = useSocialData();

  return (
    <div className={classnames(styles.socials)}>
      <p className={classnames(styles.textWrap, 'cr-reg')}>
        <span className={classnames(styles.title)}>{socials.title}</span>
        <span className={classnames(styles.delimiter)}>&#160;</span>
        <span className={classnames(styles.subtitle)}>{socials.subtitle}</span>
      </p>

      {/* TODO: использовать если вернемся к списку соц сетей в виде круглых иконок */}
      {/* <Socials socials={socialsData} /> */}

      <ul className={classnames(styles.list)}>
        {socialsData.map((link, index) => {
          return (
            <li key={index}>
              <Link
                key={index}
                to={link.url}
                className="cr-btn cr-btn--icon cr-btn--white cr-btn--bg-grey"
              >
                {socials.button}
                <Icon name={link.icon} />
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SocialsContainer;
