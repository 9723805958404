import React from 'react';
import classnames from 'classnames';
import * as styles from './partners.module.scss';

import Microsoft from 'images/microsoft-gold-partner-logo.png';
import Ifst from 'images/ifst-logo.png';
import Cecod from 'images/cecod-logo.png';

const Partners = ({ partners }) => {
  const logos = [Microsoft, Ifst, Cecod];

  return (
    <>
      <p className={classnames(styles.title, 'cr-sub-2 cr-opacity-02')}>
        {partners}{' '}
      </p>

      <ul className={classnames(styles.list)}>
        {logos.map((logo) => {
          return (
            <li key={logo}>
              <img src={logo} alt="" />
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Partners;
