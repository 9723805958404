import React, { useState, useMemo, useCallback } from 'react';
import classnames from 'classnames';
import * as styles from './style.module.scss';

import Button from 'components/UI/Button';
import Icon from 'components/UI/Icon';
import ChooseArea from './components/ChooseArea';
import ChoosePlan from './components/ChoosePlan';
import Registration from './components/Registration';

const Steps = ({ data, onFinish = () => {} }) => {
  const STEPS_KEYS = useMemo(
    () => ({
      areas: 'areas',
      plans: 'plans',
      registration: 'registration',
    }),
    []
  );

  const [result, setResult] = useState({});
  const [curStep, setCurStep] = useState(STEPS_KEYS.areas);

  const stepChangeHandler = useCallback(
    (key, data) => {
      setResult((res) => ({
        ...res,
        [key]: data,
      }));
    },
    [setResult]
  );

  const areaChangeHandler = useCallback(
    (data) => {
      stepChangeHandler(STEPS_KEYS.areas, data);
    },
    [STEPS_KEYS, stepChangeHandler]
  );

  const planChangeHandler = useCallback(
    (data) => {
      stepChangeHandler(STEPS_KEYS.plans, data);
    },
    [STEPS_KEYS, stepChangeHandler]
  );

  const registrationHandler = useCallback(
    (data) => {
      stepChangeHandler(STEPS_KEYS.registration, data);
    },
    [STEPS_KEYS, stepChangeHandler]
  );

  const STEPS_COMPONENTS = useMemo(
    () => ({
      [STEPS_KEYS.areas]: () => (
        <ChooseArea data={data.areas} onChange={areaChangeHandler} />
      ),
      [STEPS_KEYS.plans]: () => (
        <ChoosePlan data={data.plans} onChange={planChangeHandler} />
      ),
      [STEPS_KEYS.registration]: () => (
        <Registration data={data.form} onChange={registrationHandler} />
      ),
    }),
    [
      STEPS_KEYS,
      data,
      areaChangeHandler,
      planChangeHandler,
      registrationHandler,
    ]
  );

  const changeNextStep = useCallback(() => {
    const stepsValues = Object.values(STEPS_KEYS);
    const curIndex = stepsValues.findIndex((v) => v === curStep);
    const nextIndex = curIndex + 1;

    if (nextIndex >= stepsValues.length) {
      onFinish(result);

      return;
    }

    setCurStep(stepsValues[nextIndex]);
  }, [curStep, setCurStep, STEPS_KEYS, onFinish, result]);

  const stepsCount = useMemo(() => Object.keys(STEPS_KEYS).length, [
    STEPS_KEYS,
  ]);

  const progressCount = useMemo(
    () => Object.values(STEPS_KEYS).findIndex((step) => step === curStep) + 1,
    [curStep, STEPS_KEYS]
  );

  return (
    <div className={classnames(styles.steps)}>
      <div className={styles.steps_head}>
        <p
          className={classnames('cr-main cr-subheader', styles.steps_suptitle)}
        >
          {data.suptitle}
        </p>
        <p className={classnames('cr-h3')}>{data.title}</p>
      </div>

      <div className={styles.steps_main}>{STEPS_COMPONENTS[curStep]()}</div>

      <div className={styles.steps_bot}>
        <div className={classnames('cr-lead', styles.steps_progress)}>
          {progressCount}/{stepsCount}
        </div>

        <Button
          type="submit"
          className={classnames(
            'cr-btn--large cr-btn--icon-red-txt cr-btn--icon-red-bg cr-btn--bg-light',
            styles.steps_bot_btn
          )}
          disabled={!result[curStep]?.isValid}
          onClick={changeNextStep}
        >
          {stepsCount === progressCount ? data.submitBtn : data.nextBtn}
          <Icon name="arrow-right" />
        </Button>
      </div>
    </div>
  );
};

export default Steps;
