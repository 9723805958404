import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import * as defStyles from './styles.module.scss';

import SubscribeForm from 'components/Forms/Subscribe';
import SubscribeFormSuccess from 'components/Forms/Subscribe/components/Success';
import useReusableBlocks from 'hooks/graphql/useReusableBlocks';

const Subscribe = ({ styles = defStyles }) => {
  const [isSuccess, setIsSuccess] = useState(false);

  const {
    subscribeForm: { list, title },
  } = useReusableBlocks();

  const onSubmitHandler = useCallback(() => {
    setIsSuccess(true);
  }, [setIsSuccess]);

  return (
    <div className={styles.subscribe}>
      <div className={styles.subscribe_l}>
        <h2 className={classnames('cr-h2', styles.subscribe_title)}>{title}</h2>
        <ul className={classnames('cr-checked-list')}>
          {list &&
            list.map(({ itemOption }, i) => <li key={i}>{itemOption}</li>)}
        </ul>
      </div>
      <div className={styles.subscribe_r}>
        {isSuccess ? (
          <SubscribeFormSuccess />
        ) : (
          <SubscribeForm onSubmit={onSubmitHandler} />
        )}
      </div>
    </div>
  );
};

export default Subscribe;
