import React from 'react';

import Modal from 'components/UI/Modal';
import Subscribe from '@/sections/common/Subscribe';

import * as styles from './styles.module.scss';

const SubscribeModal = ({ isOpen, onClose }) => (
  <Modal
    label={'Subscribe'}
    isOpen={isOpen}
    onClose={onClose}
    maxWidth={'1000px'}
    className={styles.subscribe}
    overlayClassName={'cr-modal-overlay--as-page-on-mobile'}
  >
    <Subscribe />
  </Modal>
);

export default SubscribeModal;
