import React from 'react';
import classnames from 'classnames';
import ReactModal from 'react-modal';

import Icon from 'components/UI/Icon';
import './styles.scss';

ReactModal.setAppElement('#___gatsby');

const Modal = ({
  isOpen,
  onClose,
  children,
  label = '',
  maxWidth = '',
  className = '',
  overlayClassName = '',
}) => (
  <ReactModal
    isOpen={isOpen}
    style={{
      content: {
        maxWidth,
      },
    }}
    contentLabel={label}
    portalClassName={'cr-modal-portal'}
    overlayClassName={classnames('cr-modal-overlay', overlayClassName)}
    className={classnames('cr-modal-cont', className)}
    bodyOpenClassName={'cr-modal-body--open'}
    htmlOpenClassName={'cr-modal-html--open'}
    shouldFocusAfterRender={true}
    shouldCloseOnOverlayClick={true}
    shouldCloseOnEsc={true}
    onRequestClose={onClose}
    // shouldReturnFocusAfterClose={true}
    preventScroll={true}
  >
    <button
      type="button"
      className={'cr-modal-cont_back-btn'}
      onClick={onClose}
    >
      <Icon name={'arrow-left'} className="cr-white" />
    </button>

    <button
      type="button"
      className={'cr-modal-cont_close-btn'}
      onClick={onClose}
    >
      <Icon name={'close'} size={'16px'} />
    </button>
    {children}
  </ReactModal>
);

export default Modal;
