import { useCallback } from 'react';
import axios from 'axios';
import { useGravityFormData } from 'hooks/graphql/useFormData';

import headerCredentials from '@/helpers/headerCredentials';

const useGravityForm = (id) => {
  const { apiURL, formFields } = useGravityFormData(id);

  return useCallback(
    (inputs) => {
      const formData = new FormData();

      const results = Object.entries(inputs)
        .map(([key, value]) => {
          const input = formFields.find(({ label }) => label === key);

          return !input ? null : { [`input_${input.id}`]: value };
        })
        .filter((i) => i);

      results.forEach((obj) => {
        const key = Object.keys(obj)[0];
        formData.append(key, obj[key]);
      });

      return axios({
        url: `${apiURL}/submissions`,
        method: 'POST',
        headers: {
          ...headerCredentials(),
        },
        data: formData,
      });
    },
    [apiURL, formFields]
  );
};

export default useGravityForm;
