import React, { useState, useCallback, useMemo } from 'react';
import classnames from 'classnames';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Icon from 'components/UI/Icon';
import Spinner from 'components/UI/Spinner';
import Steps from './components/Steps';
import Finish from './components/Finish';

import useGravityForm from 'hooks/useGravityForm';
import useReusableBlocks from 'hooks/graphql/useReusableBlocks';
import useContactOnSite from 'hooks/graphql/useContactsSite';
import useStaticImage from 'hooks/graphql/useStaticImage';

import * as styles from './style.module.scss';

const GetCirrusoft = () => {
  const contacts = useContactOnSite();
  const getStaticImage = useStaticImage();
  const [isFinish, setIsFinish] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const image = useMemo(
    () =>
      getImage(getStaticImage('common/get-cirrusoft/img.jpg').childImageSharp),
    [getStaticImage]
  );

  const {
    getCirrusoft: getCirrusoftData,
    forms_ui: formsData,
  } = useReusableBlocks();
  const submitGravityForm = useGravityForm(3);

  const phone = useMemo(() => contacts.find(({ type }) => type === 'phone'), [
    contacts,
  ]);

  const STEPS_DATA = useMemo(
    () => ({
      suptitle: getCirrusoftData.label,
      title: getCirrusoftData.getCirrusPopupTitle,
      phoneTitle: getCirrusoftData.phoneTitle,
      nextBtn: getCirrusoftData.nextButtonTitle,
      submitBtn: formsData.buttons.submit,
      areas: {
        ...getCirrusoftData.areas,
        list: (getCirrusoftData.areas.list || []).map(({ title, icon }) => ({
          title,
          iconSrc: icon?.sourceUrl,
          id: title,
        })),
      },
      plans: {
        ...getCirrusoftData.countPos,
        plans: (getCirrusoftData.countPos.plans || []).map(({ text }) => ({
          id: text,
          title: text,
        })),
        selectPlaceholder: formsData.placeholders.select,
        countries: (
          getCirrusoftData.countPos.countries || []
        ).map(({ name }) => ({ value: name, label: name })),
      },
      form: {
        title: getCirrusoftData.form.title,
        placeholders: formsData.placeholders,
        termsAndConditions: formsData.termsAndConditions,
      },
    }),
    [getCirrusoftData, formsData]
  );

  const FINISH_DATA = useMemo(
    () => ({
      ...getCirrusoftData.finish,
      blogLink: formsData.success.blog,
      connectLink: formsData.success.connect,
    }),
    [getCirrusoftData, formsData]
  );

  const finishHandler = useCallback(
    (stepsValues) => {
      const values = Object.values(stepsValues).reduce(
        (acc, v) => ({ ...acc, ...v }),
        {}
      );

      setIsLoading(true);

      submitGravityForm(values)
        .then(() => {
          setIsFinish(true);
        })
        .catch((err) => {
          console.error(err.response);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [submitGravityForm, setIsLoading]
  );

  return (
    <div className={styles.quiz}>
      <div className={styles.quiz_img}>
        <GatsbyImage
          image={image}
          alt={STEPS_DATA.title}
          className={styles.quiz_img_elem}
        />

        <a
          className={classnames(
            'cr-reg cr-white cr-link',
            styles.quiz_img_phone
          )}
          href={phone.href}
        >
          <Icon name="phone-call" />
          <span>{STEPS_DATA.phoneTitle}</span>
          <span className={'cr-h5'}>{phone.value}</span>
        </a>
      </div>

      <div className={styles.quiz_form}>
        {isFinish ? (
          <Finish data={FINISH_DATA} />
        ) : (
          <Steps data={STEPS_DATA} onFinish={finishHandler} />
        )}
      </div>

      {isLoading && <Spinner size="100px" absolute />}
    </div>
  );
};

export default GetCirrusoft;
