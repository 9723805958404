import * as Yup from 'yup';
import getRules from 'assets/js/validRules';

const rules = (locale) => {
  const RULES = getRules(locale);

  return Yup.object({
    firstName: RULES.required,
    lastName: RULES.required,
    companyName: RULES.required,
    email: RULES.email,
    accept: RULES.accept,
  });
};

export default rules;
