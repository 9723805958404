import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import Icon from 'components/UI/Icon';
import * as styles from './item.module.scss';

const Item = ({ data }) => {
  const icon = data.connectedNode?.node?.servicesIcons?.icon?.white?.sourceUrl;

  return (
    <Link to={data.url} className={classnames(styles.item)}>
      <i className={classnames(styles.iconWrap)}>
        {icon ? <img src={icon} alt="" /> : <Icon name="gas-station" />}
      </i>

      <p className={classnames(styles.title, 'cr-txt-strong')}>
        {data.label ?? 'Gas Stations'}
      </p>
    </Link>
  );
};

export default Item;
