import { useStaticQuery, graphql } from 'gatsby';

export const useLanguagesList = () => {
  const { wp } = useStaticQuery(
    graphql`
      query getLanguagesList {
        wp {
          defaultLanguage {
            code
            id
            locale
            name
            slug
          }
          languages {
            code
            id
            locale
            name
            slug
          }
        }
      }
    `
  );

  return wp;
};
