const MESSAGES = {
  en: {
    required: 'Required',
    email: 'Invalid email address',
    accept: 'The terms and conditions must be accepted',
  },
  de: {
    required: 'Erforderlich',
    email: 'Ungültige E-Mail-Adresse',
    accept: 'Die Bedingungen und Konditionen müssen akzeptiert werden',
  },
};

export default MESSAGES;
