import React, { useMemo, useState, useCallback } from 'react';
import { useField } from 'formik';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

const Input = ({
  className,
  size = 'medium',
  mod = 'white',
  after = null,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [isFocused, setIsFocused] = useState(false);

  const statusCssClasses = useMemo(
    () => ({
      [styles.error]: meta.touched && meta.error,
      [styles.success]: meta.touched && !meta.error,
      [styles.focused]: isFocused,
    }),
    [meta, isFocused]
  );

  const focusHandler = useCallback(() => {
    setIsFocused(true);
  }, [setIsFocused]);

  const blurHandler = useCallback(
    (e) => {
      setIsFocused(false);
      field.onBlur(e);
    },
    [setIsFocused, field]
  );

  return (
    <div
      className={classnames(
        styles.input,
        styles[size],
        styles[mod],
        className,
        statusCssClasses
      )}
    >
      <div className={styles.input_cont}>
        <input
          className={classnames('cr-reg', styles.input_elem)}
          {...field}
          {...props}
          onFocus={focusHandler}
          onBlur={blurHandler}
        />

        {after}
      </div>

      {meta.touched && meta.error && (
        <div className={classnames('cr-sub', styles.input_error)}>
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default Input;
