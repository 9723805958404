import React, { useState } from 'react';
import classnames from 'classnames';
import * as styles from './style.module.scss';

import { Formik, Form } from 'formik';
import { Link } from 'gatsby';

import Icon from 'components/UI/Icon';
import Input from 'components/UI/InputFormik';
import Checkbox from 'components/UI/Checkbox';
import Button from 'components/UI/Button';
import Spinner from 'components/UI/Spinner';
import validationSchema from './validationSchema';

import useLocale from 'hooks/useLocale';
import useGravityForm from 'hooks/useGravityForm';
import useReusableBlocks from 'hooks/graphql/useReusableBlocks';

const SubscribeForm = ({ onSubmit = () => {} }) => {
  const locale = useLocale();
  const submitGravityForm = useGravityForm(2);
  const { forms_ui: data } = useReusableBlocks();
  const [isLoading, setIsLoading] = useState(false);

  const {
    buttons: { submit },
  } = data;

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        companyName: '',
        email: '',
        accept: false,
      }}
      validationSchema={validationSchema(locale.slug)}
      onSubmit={(values) => {
        setIsLoading(true);

        submitGravityForm({ ...values, locale: locale.code })
          .then(() => {
            onSubmit(values);
          })
          .catch((err) => {
            console.error(err.response);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }}
    >
      <Form className={styles.form} noValidate>
        <Input
          className={styles.form_input}
          name="firstName"
          placeholder={data.placeholders.name || 'Name'}
        />

        <Input
          className={styles.form_input}
          name="lastName"
          placeholder={data.placeholders.lastName || 'Surname'}
        />

        <Input
          className={styles.form_input}
          name="companyName"
          placeholder={data.placeholders.companyName || 'Company name'}
        />

        <Input
          className={styles.form_input}
          name="email"
          type="email"
          placeholder={data.placeholders.email || 'Email'}
        />

        <div className={styles.form_accept}>
          <Checkbox name="accept">
            {data.termsAndConditions.text}
            <Link
              to={data.termsAndConditions.to?.uri}
              className={'cr-link cr-main'}
            >
              &nbsp;{data.termsAndConditions.linkText}
            </Link>
          </Checkbox>
        </div>

        <div>
          <Button
            type="submit"
            className={classnames(
              'cr-btn--icon cr-btn--large cr-btn--icon-red-bg',
              styles.form_btn
            )}
          >
            {submit}
            <Icon name="corner-arrow-small-bold" />
          </Button>
        </div>

        {isLoading && <Spinner size="80px" absolute />}
      </Form>
    </Formik>
  );
};

export default SubscribeForm;
