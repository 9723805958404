import Subscribe from './index';
import useModalWithContext from 'hooks/useModalWithContext';

import { ACTIONS_CREATORS, MODALS } from '@/context';

const SubscribeModalWithContext = () =>
  useModalWithContext(
    MODALS.SUBSCRIBE,
    ACTIONS_CREATORS.setSubscribeModal,
    Subscribe
  );

export default SubscribeModalWithContext;
