import React from 'react';
import classnames from 'classnames';
import * as styles from './contactsContainer.module.scss';

import useContactOnSite from 'hooks/graphql/useContactsSite';

const SocialsContainer = ({ support }) => {
  const contacts = useContactOnSite();

  return (
    <div className={classnames(styles.contacts)}>
      <p className={classnames(styles.textWrap, 'cr-reg')}>
        <span className={classnames(styles.title)}>{support.title}</span>
        <span className={classnames(styles.delimiter)}>&#160;</span>
        <span className={classnames(styles.subtitle)}>{support.subtitle}</span>
      </p>

      <ul className={classnames(styles.list)}>
        {contacts.map((contact, index) => (
          <li key={contact.href}>
            <a
              href={contact.href}
              className={classnames(
                index % 2 === 0 && 'cr-main',
                'cr-link cr-subheader-3'
              )}
              target="_blank"
              rel="noreferrer"
            >
              {contact.value}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SocialsContainer;
