import React, { useEffect } from 'react';
import * as RDD from 'react-device-detect';
import { useLocation } from '@reach/router';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import classnames from 'classnames';

import SEO from 'sections/Layout/SEO';

import Header from 'sections/Layout/Header';
import Footer from 'sections/Layout/Footer';
import CookiesPopup from 'sections/Layout/CookiePopup';
import GetCirrusoftModalWithContext from 'components/Modals/GetCirrusoft/modalWithContext';
import SubscribeModalWithContext from 'components/Modals/Subscribe/modalWithContext';

import useUrlHash from 'hooks/useUrlHash';
import useHashModals from 'hooks/useHashModals';

const Layout = ({
  children,
  currentPageClass,
  headerClass,
  mainClass,
  footerClass,
  seo,
  postBanner = null,
}) => {
  const location = useLocation();

  const openModalsByHash = useHashModals();

  useUrlHash(openModalsByHash);

  useEffect(() => {
    const htmlEl = document.documentElement;
    const bodyEl = document.body;

    RDD.isDesktop &&
      !RDD.isIOS &&
      !RDD.isMacOs &&
      htmlEl.classList.add('cr-custom-scroll') &&
      bodyEl.classList.add('cr-custom-scroll');
  }, []);

  useEffect(() => {
    clearAllBodyScrollLocks();
  }, [location.pathname]);

  return (
    <>
      <SEO seo={seo} postBanner={postBanner} />
      <div className={classnames(currentPageClass, 'cr-page-wrapper')}>
        <Header className={headerClass} />
        <main className={mainClass}>{children()}</main>
        <Footer className={footerClass} />

        <GetCirrusoftModalWithContext />
        <SubscribeModalWithContext />
        <CookiesPopup />
      </div>
    </>
  );
};

export default Layout;
