import React from 'react';
import classnames from 'classnames';
import * as styles from './headerLogo.module.scss';

const HeaderLogo = ({ className = '' }) => {
  return (
    <svg
      className={classnames(styles.logo, className)}
      id="cirrus-logo-black"
      width="117"
      height="27"
      viewBox="0 0 117 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M31.992 6.77v19.042h-8.584V6.77h8.584z" fill="#EF2B1A" />
      <path
        d="M37.669.97c1.644-.338 3.996-.523 6.239-.523 3.473 0 5.714.634 7.284 2.055 1.27 1.121 1.98 2.84 1.98 4.78 0 3.325-2.092 5.53-4.745 6.426v.112c1.943.672 3.1 2.466 3.697 5.08.822 3.512 1.419 5.94 1.944 6.91h-3.362c-.412-.71-.972-2.877-1.682-6.014-.747-3.474-2.092-4.781-5.043-4.895H40.92v10.91h-3.25V.968zm3.25 11.469h3.324c3.474 0 5.678-1.906 5.678-4.781 0-3.25-2.352-4.67-5.79-4.707-1.568 0-2.69.15-3.212.299v9.189zM57.765.97C59.409.631 61.76.446 64.004.446c3.474 0 5.714.634 7.284 2.055 1.271 1.121 1.98 2.84 1.98 4.78 0 3.325-2.091 5.53-4.745 6.426v.112c1.943.672 3.1 2.466 3.697 5.08.822 3.512 1.42 5.94 1.944 6.91h-3.362c-.411-.71-.972-2.877-1.682-6.014-.746-3.474-2.091-4.781-5.042-4.895h-3.063v10.91h-3.25V.968zm3.25 11.469h3.324c3.474 0 5.678-1.906 5.678-4.781 0-3.25-2.352-4.67-5.79-4.707-1.568 0-2.69.15-3.212.299v9.189zM81.111.634V15.54c0 5.64 2.502 8.031 5.864 8.031 3.735 0 6.126-2.466 6.126-8.031V.634h3.286v14.682c0 7.732-4.072 10.909-9.525 10.909-5.154 0-9.04-2.951-9.04-10.76V.635h3.29zM101.58 21.853c1.457.896 3.586 1.644 5.828 1.644 3.325 0 5.268-1.756 5.268-4.296 0-2.353-1.345-3.698-4.745-5.006-4.108-1.457-6.648-3.586-6.648-7.136-.002-3.923 3.248-6.836 8.143-6.836 2.577 0 4.445.598 5.566 1.233l-.896 2.652a10.016 10.016 0 00-4.78-1.195c-3.436 0-4.745 2.055-4.745 3.773 0 2.353 1.532 3.512 5.006 4.857 4.258 1.644 6.425 3.697 6.425 7.397 0 3.885-2.877 7.247-8.815 7.247-2.428 0-5.08-.71-6.425-1.607l.818-2.727zM0 13.149C0 5.312 5.307 0 13.063 0 16.181 0 18.148.669 19 1.115l-.78 2.637c-1.224-.595-2.968-1.04-5.046-1.04-5.863 0-9.76 3.752-9.76 10.327 0 6.13 3.526 10.068 9.611 10.068 1.967 0 3.97-.409 5.27-1.04l.667 2.563c-1.187.596-3.562 1.189-6.605 1.189C5.307 25.815 0 21.359 0 13.149z"
        fill="#12192F"
      />
      <path
        className={styles.animatedElement}
        d="M31.992.634v4.101h-8.584V.634h8.584z"
        fill="#EF2B1A"
      />
    </svg>
  );
};

export default HeaderLogo;
