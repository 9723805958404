import React from 'react';
import classnames from 'classnames';
import './styles.scss';

const baseClass = 'cr-btn';

const Button = ({
  className = '',
  type = 'button',
  children,
  onClick,
  ...props
}) => {
  return (
    <button
      type={type}
      className={classnames(baseClass, className)}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
