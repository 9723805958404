import React from 'react';
import classnames from 'classnames';
import * as styles from './style.module.scss';

import Socials from 'components/Elements/Socials/Links';
import useSocialData from 'hooks/graphql/useSocials';

const ContactLink = ({ data, className }) => {
  const socialsData = useSocialData();

  return (
    <div className={classnames(styles.link, className)}>
      <div className={styles.link_l}>
        <p className={classnames('cr-lead', styles.link_title)}>
          {data?.title}
        </p>

        <p className={classnames('cr-reg')}>{data?.subtitle}</p>
      </div>
      <div className={styles.link_r}>
        <Socials socials={socialsData} mod={'black'} />
      </div>
    </div>
  );
};

export default ContactLink;
