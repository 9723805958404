import React, { useRef, useState, useEffect, useCallback } from 'react';
import useHover from '@react-hook/hover';
import { Link } from 'gatsby';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

import Icon from 'components/UI/Icon';

import useDeviceState from 'hooks/useDeviceState';
import { DESKTOP_KEY } from 'constants/devices';
import useLanguage from 'hooks/useLanguage';
import useClickOutside from 'hooks/useClickOutside';

import * as styles from './langSwitch.module.scss';

const LangSwitch = () => {
  const { listLanguages, currentLanguage } = useLanguage();
  const targetHover = useRef(null);
  const isHovering = useHover(targetHover, { enterDelay: 100, leaveDelay: 0 });
  const deviceState = useDeviceState();

  const [isShowPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setShowPopup(isHovering);
  }, [isHovering]);

  useClickOutside(targetHover, () => {
    setShowPopup(false);
  });

  const handleClick = useCallback(() => {
    setShowPopup((state) => !state);
  }, [setShowPopup]);

  return (
    <div
      className={classnames(styles.lang)}
      ref={targetHover}
      onClick={handleClick}
    >
      <p
        className={classnames(
          styles.btn,
          isShowPopup && 'cr-dropdown-open',
          'cr-text-2 ct-txt-up cr-dropdown-arrow'
        )}
      >
        <span>{currentLanguage.code}</span>
        <Icon name="chevron-down" />
      </p>

      {deviceState === DESKTOP_KEY && (
        <CSSTransition
          in={isShowPopup}
          classNames="cr-css-transition--fade"
          timeout={{
            enter: 200,
            exit: 0,
          }}
          unmountOnExit
        >
          <ul className={classnames(styles.list)}>
            {listLanguages.length &&
              listLanguages.map((lang) => (
                <li key={lang.path}>
                  <Link
                    to={`/${lang.path}`}
                    className={classnames(
                      currentLanguage.code === lang.code
                        ? styles.activeLink
                        : '',
                      'cr-link'
                    )}
                  >
                    {lang.code}
                  </Link>
                </li>
              ))}
          </ul>
        </CSSTransition>
      )}
    </div>
  );
};

export default LangSwitch;
