import React, { useMemo } from 'react';
import classnames from 'classnames';
import * as styles from './copy.module.scss';

const Copy = ({ copy, developed }) => {
  const currentYear = useMemo(() => {
    const date = new Date();

    return date.getFullYear();
  }, []);

  return (
    <div className={classnames(styles.copy, 'cr-sub')}>
      <p className={classnames(styles.copy_year, 'cr-opacity-02')}>
        {currentYear} {copy}
      </p>

      <p className={classnames(styles.copy_dev)}>
        <span className="cr-opacity-02">{developed}</span>&#160;&#160;
        <a
          href="https://capptoo.com/"
          className="cr-link"
          target="_blank"
          rel="noreferrer"
        >
          <span>&#9665;&#9655;</span>&#160;CAPPTOO
        </a>
      </p>
    </div>
  );
};

export default Copy;
