import { useEffect } from 'react';
import { useLocation } from '@reach/router';

const useUrlHash = (handler = () => {}) => {
  const location = useLocation();

  useEffect(() => {
    handler(location.hash);
  }, [location.hash, handler]);
};

export default useUrlHash;
