import { useCallback } from 'react';
import { useContextDispatch, ACTIONS_CREATORS } from '@/context';

const useGetCirrusoftModal = () => {
  const dispatch = useContextDispatch();

  const openGetCirrusModal = useCallback(() => {
    dispatch(ACTIONS_CREATORS.setGetCirrusoftModal(true));
  }, [dispatch]);

  const closeGetCirrusModal = useCallback(() => {
    dispatch(ACTIONS_CREATORS.setGetCirrusoftModal(false));
  }, [dispatch]);

  return {
    openGetCirrusModal,
    closeGetCirrusModal,
  };
};

export default useGetCirrusoftModal;
