import { useStaticQuery, graphql } from 'gatsby';
import useLocale from 'hooks/useLocale';

const useReusableSections = () => {
  const currentLocale = useLocale();

  const {
    allWpReusableSection: { nodes },
  } = useStaticQuery(
    graphql`
      query ReusableBlockQuery {
        allWpReusableSection {
          nodes {
            template {
              ... on WpTemplate_Reusable404Page {
                notFoundPage {
                  title
                  subtitle
                  button {
                    url {
                      title
                      url
                    }
                    title
                  }
                }
              }
              ... on WpTemplate_ReusablePartners {
                partners {
                  logos {
                    logo {
                      sourceUrl
                    }
                  }
                }
              }
              ... on WpTemplate_ReusableJoin {
                joinSection {
                  description
                  title
                  link {
                    text
                    to {
                      ... on WpPage {
                        id
                        uri
                      }
                    }
                  }
                }
              }
              ... on WpTemplate_ReusableBecomeFriend {
                becomeFriend {
                  subtitle
                  title
                }
              }
              ... on WpTemplate_ReusableForm {
                forms_ui {
                  placeholders {
                    companyName
                    email
                    fieldGroupName
                    name
                    lastName
                    phone
                    select
                    submitYourCv
                    dragUpload {
                      dragBox
                      clickUpload
                    }
                  }
                  success {
                    blog {
                      subtitle
                      title
                      to {
                        ... on WpPage {
                          id
                          uri
                        }
                      }
                    }
                    connect {
                      subtitle
                      title
                    }
                    next
                    sendText
                    subtitle
                    title
                  }
                  buttons {
                    readMore
                    submit
                    subscribe
                    getCirrusoft
                    goToHomePage
                    connect
                    nextPost
                    previewPost
                  }
                  termsAndConditions {
                    linkText
                    text
                    to {
                      ... on WpPage {
                        id
                        uri
                      }
                    }
                  }
                }
                getCirrusoft {
                  getCirrusPopupTitle
                  label
                  phoneTitle
                  nextButtonTitle
                  areas {
                    title
                    list {
                      title
                      icon {
                        sourceUrl
                      }
                    }
                  }
                  countPos {
                    planTitle
                    plans {
                      text
                    }
                    countriesTitle
                    countries {
                      name
                    }
                  }
                  form {
                    title
                  }
                  finish {
                    title
                    suptitle
                    description
                    linksTitle
                  }
                }
                registerForm {
                  registerSubtitle
                  registerTitle
                  registerSuccess {
                    suptitle
                    title
                    description
                  }
                }
                topicForMessageSelector {
                  placeholder
                  options {
                    topic
                  }
                }
                subscribeForm {
                  title
                  list {
                    itemOption
                  }
                  subscribeSuccess {
                    suptitle
                    title
                    description
                  }
                }
              }

              ... on WpTemplate_ReusableFooter {
                footer {
                  contactsSubtitle
                  contactsTitle
                  copyrightText
                  ownersTitle1
                  ownersTitle2
                  ownersHref2
                  partnersTitle
                  socialsSubtitle
                  socialsTitle
                  socialButton
                  developed
                  subscribeSubtitle
                  subscribeTitle
                  termsLinks {
                    ... on WpPage {
                      id
                      uri
                      title
                    }
                  }
                  privacyLink {
                    ... on WpPage {
                      id
                      uri
                      title
                    }
                  }
                }
              }

              ... on WpTemplate_ReusableCookiesPopup {
                templateName
                cookiesPopup {
                  acceptButton
                  declineButton
                  content
                }
              }
            }
            translations {
              id
            }
            language {
              code
            }
          }
        }
      }
    `
  );

  return nodes
    .filter(({ template }) => Object.keys(template).length > 0)
    .filter(
      ({ language, translations }) =>
        translations.length === 0 || language.code === currentLocale.code
    )
    .map(({ template }) => ({
      ...template,
    }))
    .reduce(
      (obj, el) => ({
        ...el,
        ...obj,
      }),
      {}
    );
};

export default useReusableSections;
