import React from 'react';
import classnames from 'classnames';
import Item from './Item';

import * as styles from './focusDropdown.module.scss';

const FocusMenu = ({ data }) => (
  <div className={classnames(styles.dropdown)}>
    <ul className={classnames(styles.list)}>
      {data.map((item) => (
        <li key={item.url}>
          <Item data={item} />
        </li>
      ))}
    </ul>
  </div>
);

export default FocusMenu;
