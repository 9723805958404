import React, { useCallback } from 'react';
import classnames from 'classnames';
import * as styles from './style.module.scss';

import { Formik, Form } from 'formik';

import Input from 'components/UI/InputFormik';
import Checkbox from 'components/UI/Checkbox';

import useLocale from 'hooks/useLocale';

import validationSchema from './validationSchema';

const RegistrationForm = ({ data, onChange }) => {
  const onChangeHandler = useCallback(
    (values, validateForm) => {
      setTimeout(async () => {
        const errors = await validateForm();
        const isValid = Object.keys(errors).length === 0;

        onChange({
          ...values,
          isValid,
        });
      }, 100);
    },
    [onChange]
  );
  const locale = useLocale();

  return (
    <Formik
      initialValues={{
        name: '',
        companyName: '',
        phone: '',
        email: '',
        accept: false,
      }}
      validationSchema={validationSchema(locale.slug)}
      validateOnMount
    >
      {({ validateForm, values, setFieldTouched }) => {
        return (
          <Form
            noValidate
            onChange={() => {
              onChangeHandler(values, validateForm);
            }}
          >
            <p className={classnames('cr-reg', styles.form_title)}>
              {data.title}
            </p>

            <ul className={styles.form_inputs}>
              <li>
                <Input
                  name="name"
                  type="text"
                  placeholder={data.placeholders.name}
                />
              </li>
              <li>
                <Input
                  name="companyName"
                  type="text"
                  placeholder={data.placeholders.companyName}
                />
              </li>
              <li>
                <Input
                  name="phone"
                  type="tel"
                  placeholder={data.placeholders.phone}
                />
              </li>
              <li>
                <Input
                  name="email"
                  type="email"
                  placeholder={data.placeholders.email}
                />
              </li>
            </ul>

            <div>
              <Checkbox
                name="accept"
                onInput={() => {
                  setFieldTouched('accept', true);
                }}
              >
                {data.termsAndConditions.text}&#160;
                <a
                  href={data.termsAndConditions.to?.uri}
                  className={'cr-link cr-main'}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data.termsAndConditions.linkText}
                </a>
              </Checkbox>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RegistrationForm;
