import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import * as styles from './style.module.scss';

import Icon from 'components/UI/Icon';
import RadioBtn from 'components/UI/RadioBtn';

const ChooseAreas = ({ data, onChange }) => {
  const [curArea, setCurArea] = useState(null);

  const radioChangeHandler = useCallback(
    (area) => {
      setCurArea(area);
      onChange({ isValid: !!area, area });
    },
    [setCurArea, onChange]
  );

  return (
    <div>
      <p className={classnames('cr-reg', styles.areas_title)}>{data.title}</p>

      <ul className={styles.areas_list}>
        {data.list.map((area) => (
          <li key={area.id}>
            <RadioBtn
              name={'area'}
              isActive={curArea === area.id}
              className={classnames(styles.radio, {
                [styles.active]: curArea === area.id,
              })}
              onChange={() => {
                radioChangeHandler(area.id);
              }}
            >
              <img src={area.iconSrc} alt="" className={styles.radio_icon} />
              <span className={classnames('cr-reg')}>{area.title}</span>
              <Icon
                name={'checked'}
                className={classnames('cr-main', styles.radio_checked)}
              />
            </RadioBtn>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChooseAreas;
