import React from 'react';
import classnames from 'classnames';
import * as styles from './socials.module.scss';

import Icon from 'components/UI/Icon';

const Socials = ({ socials, mod }) => (
  <div>
    <ul className={classnames(styles.list, styles[mod])}>
      {socials.map((social) => (
        <li key={social.url} className={classnames(styles.item)}>
          <a
            href={social.url}
            target="_blank"
            rel="noreferrer"
            className={classnames(styles.link, 'cr-bounce-scal')}
            title={social.icon}
          >
            <Icon name={social.icon} />
          </a>
        </li>
      ))}
    </ul>
  </div>
);

export default Socials;
