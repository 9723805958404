import React from 'react';
import classnames from 'classnames';
import * as styles from './style.module.scss';

import Icon from 'components/UI/Icon';

import useReusableBlocks from 'hooks/graphql/useReusableBlocks';

const Success = () => {
  const { subscribeForm: data } = useReusableBlocks();

  return (
    <div className={styles.success}>
      <p className={styles.success_suptitle}>
        <Icon name={'checked-circled'} className={'cr-text-3'} />
        <span className={classnames('cr-subheader cr-text-3')}>
          {data.subscribeSuccess?.suptitle}
        </span>
      </p>

      <p className={classnames('cr-h3', styles.success_title)}>
        {data.subscribeSuccess?.title}
      </p>

      <p className={classnames('cr-reg')}>
        {data.subscribeSuccess?.description}
      </p>
    </div>
  );
};

export default Success;
