import { useStaticQuery, graphql } from 'gatsby';

export const useMenuData = () => {
  const {
    allWpMenu: { nodes },
  } = useStaticQuery(
    graphql`
      query MenuQuery {
        allWpMenu {
          nodes {
            locations
            name
            slug
            menuItems {
              nodes {
                url
                title
                target
                path
                order
                label
                linkRelationship
                description
                parentId
                childItems {
                  nodes {
                    url
                    title
                    target
                    path
                    order
                    label
                    connectedNode {
                      node {
                        ... on WpPage {
                          id
                          servicesIcons {
                            icon {
                              white {
                                sourceUrl
                                srcSet
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return nodes.map((node) => ({
    ...node,
    menuItems: {
      nodes: node.menuItems.nodes.filter(({ parentId }) => !parentId),
    },
  }));
};
