import React, { useCallback } from 'react';
import classnames from 'classnames';
import Button from 'components/UI/Button';
import LangList from './LangList';
import Navigation from '../Navigation';
import Icon from 'components/UI/Icon';
import FocusMenu from 'components/FocusMenu';

import useLanguage from 'hooks/useLanguage';
import useGetCirrusoftModal from 'hooks/useGetCirrusoftModal';
import useReusableBlocks from 'hooks/graphql/useReusableBlocks';

import { getMainMenu } from 'sections/Layout/helpers/getPositionMenu';
import { useMenuData } from 'sections/Layout/hooks/useMenu';

import * as styles from './mobileMenu.module.scss';

const MobileMenu = ({ locale, onOpenGetCirrusoft = () => {} }) => {
  const menu = useMenuData();

  const { openGetCirrusModal } = useGetCirrusoftModal();
  const { listLanguages, currentLanguage } = useLanguage();
  const {
    forms_ui: {
      buttons: { getCirrusoft },
    },
  } = useReusableBlocks();

  const openGetCirrusModalHandler = useCallback(() => {
    onOpenGetCirrusoft();
    openGetCirrusModal();
  }, [openGetCirrusModal, onOpenGetCirrusoft]);

  const {
    menuItems: { nodes },
  } = locale.isDefault
    ? menu.find((item) => getMainMenu(item))
    : menu.find((item) => getMainMenu(item, locale.code));

  return (
    <div className={classnames(styles.mobileMenu)}>
      <div className={classnames(styles.inner, 'cr-wrap')}>
        <div className={classnames(styles.lang)}>
          <LangList
            listLanguages={listLanguages}
            currentLanguage={currentLanguage}
          />
        </div>

        <div className={classnames(styles.nav)}>
          <Navigation locale={locale} />
        </div>

        <div>
          {nodes
            .sort((a, b) => a.order - b.order)
            .map((item, index) => {
              return (
                item.childItems.nodes.length > 0 && (
                  <FocusMenu
                    data={item.childItems.nodes.sort(
                      (a, b) => a.order - b.order
                    )}
                    key={index}
                  />
                )
              );
            })}
        </div>

        <div className={classnames(styles.btn)}>
          <Button
            className={classnames(
              'cr-btn cr-btn--icon cr-btn--large cr-btn--wide cr-btn--icon-red-bg'
            )}
            onClick={openGetCirrusModalHandler}
          >
            {getCirrusoft}
            <Icon name="corner-arrow-small-bold" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
