import React from 'react';
import classnames from 'classnames';
import Owners from './Owners';
import Privacy from './Privacy';
import Copy from './Copy';
import Partners from './Partners';

import * as styles from './footerInfo.module.scss';

const FooterInfo = ({ info }) => {
  return (
    <div className={classnames(styles.footerInfo)}>
      <div className={classnames(styles.inner)}>
        <div className={classnames(styles.general)}>
          <Privacy privacy={info.privacyLink} terms={info.termsLinks} />
          <Copy copy={info.copyRight} developed={info.developed} />
        </div>
        <div className={classnames(styles.partners)}>
          <Partners partners={info.titlePartners} />
        </div>
        <div className={classnames(styles.owners)}>
          <Owners owners={[info.owner1, info.owner2]} />
        </div>
      </div>
    </div>
  );
};

export default FooterInfo;
