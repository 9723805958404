import { useMemo, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import { useLanguagesList } from 'hooks/graphql/useLanguagesList.js';

const useLocale = () => {
  const { languages, defaultLanguage } = useLanguagesList();

  const location = useLocation();

  useEffect(() => {
    location.pathname === `/${defaultLanguage.slug}/` && navigate('/');
  }, [location.pathname, defaultLanguage]);

  const locale = useMemo(() => {
    const locale = languages.find(({ slug }) => {
      const regex = new RegExp(`(^/${slug}$)|(^/${slug}/)`);

      return regex.test(location.pathname);
    });

    return {
      locale: locale || defaultLanguage,
      path: locale ? `/${locale.slug}/` : '/',
    };
  }, [defaultLanguage, languages, location.pathname]);

  useEffect(() => {
    document.documentElement.lang = locale.locale.locale;
  }, [locale]);

  return {
    ...locale.locale,
    path: locale.path,
    isDefault: defaultLanguage.code === locale.locale.code,
  };
};

export default useLocale;
