import React from 'react';
import classnames from 'classnames';
import Link from 'components/UI/Link';

import Icon from 'components/UI/Icon';
import * as styles from './footerNavigation.module.scss';

import { getFooterMenu } from 'sections/Layout/helpers/getPositionMenu';
import { useMenuData } from 'sections/Layout/hooks/useMenu';

const FooterNavigation = ({ locale }) => {
  const menu = useMenuData();

  const {
    menuItems: { nodes },
  } = locale.isDefault
    ? menu.find((item) => getFooterMenu(item))
    : menu.find((item) => getFooterMenu(item, locale.code));

  return (
    <div className={classnames(styles.footerNavigation)}>
      <div
        className={classnames(
          styles.logoWrap,
          'cr-footer-first-flex cr-footer-block-right-gap'
        )}
      >
        <Link to={locale.path} className={classnames(styles.logo)}>
          <span hidden>Home Page</span>
          <Icon name={'cirrus-logo-white'} />
        </Link>
      </div>
      <ul className={classnames(styles.list)}>
        {nodes.map((item) => {
          return (
            <li key={item.label} className={classnames(styles.listItem)}>
              {item.path && item.path !== '#' ? (
                <Link to={item.path} className={classnames(styles.listLink)}>
                  <span className={'cr-subheader-3 cr-txt-strong'}>
                    {item.label}
                  </span>
                </Link>
              ) : (
                <span className={'cr-subheader-3 cr-txt-strong'}>
                  {item.label}
                </span>
              )}

              {item?.childItems?.nodes?.length > 0 && (
                <ul>
                  {item?.childItems?.nodes.map((subItem) => {
                    return (
                      <li
                        key={subItem.url}
                        className={classnames(styles.sublistItem)}
                      >
                        <Link
                          to={subItem.url}
                          className={classnames(styles.sublistLink)}
                        >
                          <span className={'cr-subheader-3'}>
                            {subItem.label}
                          </span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FooterNavigation;
