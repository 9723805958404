import React, { useCallback } from 'react';
import { useContextDispatch, useContextState } from '@/context';

const useModalWithContext = (modalName, actionCreator, Modal) => {
  const { curModal } = useContextState();
  const dispatch = useContextDispatch();

  const handleCloseModal = useCallback(() => {
    dispatch(actionCreator(false));
  }, [dispatch, actionCreator]);

  return <Modal isOpen={curModal === modalName} onClose={handleCloseModal} />;
};

export default useModalWithContext;
