import React, { useState, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import * as styles from './style.module.scss';

import Select from 'react-select';

import RadioBtn from 'components/UI/RadioBtn';
import Icon from 'components/UI/Icon/index';

import 'components/UI/Select/styles.scss';

const ChoosePlan = ({ data, onChange }) => {
  const [curPlan, setCurPlan] = useState(null);
  const [curCountry, setCurCountry] = useState(null);

  useEffect(() => {
    onChange({
      isValid: !!curPlan && !!curCountry,
      plan: curPlan,
      country: curCountry,
    });
  }, [curPlan, curCountry, onChange]);

  const radioChangeHandler = useCallback(
    (plan) => {
      setCurPlan(plan);
    },
    [setCurPlan]
  );

  const selectChangeHandler = useCallback(
    (country) => {
      setCurCountry(country.value);
    },
    [setCurCountry]
  );

  return (
    <div>
      <p className={classnames('cr-reg', styles.plan_title)}>
        {data.planTitle}
      </p>

      <ul className={styles.plan_list}>
        {data.plans.map((plan) => (
          <li key={plan.id}>
            <RadioBtn
              name="plan"
              className={styles.plan_radio}
              isActive={curPlan === plan.id}
              onChange={() => {
                radioChangeHandler(plan.id);
              }}
            >
              <span className={classnames('cr-reg')}>{plan.title}</span>
            </RadioBtn>
          </li>
        ))}
      </ul>

      <p className={classnames('cr-reg', styles.plan_title)}>
        {data.countriesTitle}
      </p>

      <Select
        className="cr-select"
        classNamePrefix="cr-select"
        options={data.countries}
        isSearchable={false}
        components={{
          DropdownIndicator: () => <Icon name={'arrow-down'} />,
        }}
        onChange={selectChangeHandler}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        placeholder={data.selectPlaceholder}
      />
    </div>
  );
};

export default ChoosePlan;
