import React from 'react';
import classnames from 'classnames';
import * as styles from './style.module.scss';

import Icon from 'components/UI/Icon';
import ContactLink from 'components/UI/ContactLink';
import LargeLink from 'components/UI/LargelInk';

const Finish = ({ data }) => {
  return (
    <div className={styles.finish}>
      <div className={styles.finish_head}>
        <p className={styles.finish_head_suptitle}>
          <Icon name={'checked-circled'} className={'cr-text-3'} />
          <span className={classnames('cr-subheader-4 cr-text-3')}>
            {data.suptitle}
          </span>
        </p>

        <p className={classnames('cr-h3', styles.finish_head_title)}>
          {data.title}
        </p>

        <p className={classnames('cr-reg')}>{data.description}</p>
      </div>

      <div>
        <p
          className={classnames(
            'cr-main cr-subheader-3 cr-txt-up',
            styles.finish_main_title
          )}
        >
          {data.linksTitle}
        </p>

        <ul className={styles.finish_main_links}>
          <li>
            <LargeLink data={data.blogLink} />
          </li>

          <li>
            <ContactLink data={data.connectLink} />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Finish;
